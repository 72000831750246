import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const page_path = '../../container/pages/';

const AddPost = lazy(() => import('../../container/pages/AddPost'));
const PostList = lazy(() => import('../../container/pages/PostList'));
const EditPost = lazy(() => import('../../container/pages/EditPost'));
const ViewPost = lazy(() => import('../../container/pages/ViewPost'));

const AddPins = lazy(() => import('../../container/pages/AddPins'));
const DefaultImage = lazy(() => import('../../container/pages/DefaultImage'));
const DefaultLink = lazy(() => import('../../container/pages/DefaultLink'));
const PinList = lazy(() => import('../../container/pages/PinList'));
const EditPins = lazy(() => import('../../container/pages/EditPins'));
const ViewPins = lazy(() => import('../../container/pages/ViewPins'));

const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const EditUser = lazy(() => import('../../container/pages/EditUsers'));
const ViewUser = lazy(() => import('../../container/pages/ViewUsers'));
const UsersList = lazy(() => import('../../container/pages/UsersList'));

const Board = lazy(() => import('../../components/PinterestBoard/TrelloApp'));
const NewBoard = lazy(() => import('../../ComponentsTrello/BoardApp'));
const Schedule = lazy(() => import('../../components/Schedules/TrelloApp'));
const Pin = lazy(() => import('../../container/pages/Pin'));

const CustomerList = lazy(() => import('../../container/pages/CustomerList'));
const AddCustomer = lazy(() => import('../../container/pages/AddCustomer'));
const ViewCustomer = lazy(() => import('../../container/pages/ViewCustomer'));
const EditCustomer = lazy(() => import('../../container/pages/EditCustomer'));

const ChatGpt = lazy(() => import('../../container/pages/ChatGpt'));
const Configuration = lazy(() => import('../../container/pages/Configuration'));
const Templates = lazy(() => import('../../container/pages/Templates'));
const WorkFlow = lazy(() => import('../../container/pages/WorkFlow'));
const Instagram = lazy(() => import('../../container/pages/Instagram'));
const Facebook = lazy(() => import('../../container/pages/Facebook'));
const Twitter = lazy(() => import('../../container/pages/Twitter'));
const LinkedIn = lazy(() => import('../../container/pages/LinkedIn'));
const SocialAccounts = lazy(() => import('../../container/pages/SocialAccounts'));

const Instagram1 = lazy(() => import('../../container/pages/Instagram1'));
const Facebook1 = lazy(() => import('../../container/pages/Facebook1'));
const Twitter1 = lazy(() => import('../../container/pages/Twitter1'));
const LinkedIn1 = lazy(() => import('../../container/pages/Linkedin1'));
const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* 1 */}
      <Route path={`${path}/add-post`} component={AddPost} />
      <Route path={`${path}/post-list`} component={PostList} />
      <Route path={`${path}/edit-post/:id`} component={EditPost} />
      <Route path={`${path}/view-post/:id`} component={ViewPost} />
      <Route path={`${path}/default-image`} component={DefaultImage} />
      <Route path={`${path}/default-link`} component={DefaultLink} />
      <Route path={`${path}/add-pins`} component={AddPins} />
      <Route path={`${path}/pin-list`} component={PinList} />
      <Route path={`${path}/edit-pins/:id`} component={EditPins} />
      <Route path={`${path}/view-pins/:id`} component={ViewPins} />
      <Route path={`${path}/add-user`} component={AddUser} />
      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />
      <Route path={`${path}/users`} component={UsersList} />
      <Route path={`${path}/customer-list`} component={CustomerList} />
      <Route path={`${path}/add-customer`} component={AddCustomer} />
      <Route path={`${path}/view-customer/:id`} component={ViewCustomer} />
      <Route path={`${path}/edit-customer/:id`} component={EditCustomer} />
      <Route path={`${path}/instagram`} component={Instagram} />
      <Route path={`${path}/facebook`} component={Facebook} />
      <Route path={`${path}/twitter`} component={Twitter} />
      <Route path={`${path}/linkedin`} component={LinkedIn} />
      <Route exact path={`${path}/social_accounts`} component={SocialAccounts} />
      <Route exact path={`${path}/Board`} component={Board} />
      <Route exact path={`${path}/NewBoard`} component={NewBoard} />
      <Route exact path={`${path}/Pinterest`} component={Pin} />
      <Route exact path={`${path}/Schedule`} component={Schedule} />
      <Route path={`${path}/chatgpt`} component={ChatGpt} />
      <Route path={`${path}/configuration`} component={Configuration} />
      <Route path={`${path}/templates`} component={Templates} />

      <Route path={`${path}/workflow`} component={WorkFlow} />
      <Route path={`${path}/s-instagram`} component={Instagram1} />
      <Route path={`${path}/s-facebook`} component={Facebook1} />
      <Route path={`${path}/s-twitter`} component={Twitter1} />
      <Route path={`${path}/s-linkedin`} component={LinkedIn1} />
      <Route exact path={`${path}/Board`} component={Board} />
    </Switch>
  );
};
export default PagesRoute;
